// Models
import {
  ResourceDropdownItem,
  SearchTypes,
  TypeDropdownOption,
} from 'app/modules/search/models';
import { PaginationPayload } from 'app/shared/pagination/models';

// Selectors
import { selectActionEventsSearchResults } from 'app/modules/search/selectors';

// Helpers
import {
  formatEntityTopBarSearch,
  formatAlertTopBarSearch,
  formatEventTopBarSearch,
  formatActionEventsTopBarSearch,
  formatInstrumentTopBarSearch,
  formatTeamTopBarSearch,
  formatRuleTopBarSearch,
  formatCaseTopBarSearch,
} from 'app/modules/search/helpers';

// Utils
import permissions from 'app/shared/utils/permissions';
import {
  IconCoin,
  IconUser,
  IconLock,
  IconCreditCard,
  IconScale,
  IconBriefcase,
  IconFlag,
  IconUsers,
} from '@u21/tabler-icons';
import {
  AlertSearchOptions,
  EntitySearchOptions,
  MatchType,
} from 'app/modules/search/enum';
import { SearchCondition } from 'app/modules/search/payload';

export const UNIT21_ID_FIELD_NOT_FOUND = 'UNIT21_ID_NOT_FOUND';

export const DEFAULT_SEARCH_PAGINATION_PAYLOAD: PaginationPayload = {
  offset: 1,
  limit: 5,
};

export const EVENTS_TYPE_OPTIONS: TypeDropdownOption[] = [
  {
    key: 'Event ID',
    text: 'Event ID',
    value: 'external_id',
    placeholder: 'external id (requires exact match)',
  },
];

export const ACTION_EVENTS_TYPE_OPTIONS: TypeDropdownOption[] = [
  {
    key: 'Event ID',
    text: 'Event ID',
    value: 'external_id',
    placeholder: 'external id (requires exact match)',
  },
];

export const INSTRUMENTS_TYPE_OPTIONS: TypeDropdownOption[] = [
  {
    key: 'Instrument ID',
    text: 'Instrument ID',
    value: 'external_id',
    placeholder: 'instrument id',
  },
];

export const ENTITIES_TYPE_OPTIONS: TypeDropdownOption[] = [
  {
    key: 'Entity ID',
    text: 'Entity ID',
    value: 'external_id',
    placeholder: 'entity id',
  },
  {
    key: 'Business Name',
    text: 'Business Name',
    value: 'name',
    placeholder: 'business name',
  },
  {
    key: 'User Name',
    text: 'User Name',
    value: 'user_name',
    placeholder: 'user name (first name, last name or full name)',
  },
  {
    key: 'Email Address',
    text: 'Email Address',
    value: 'email_address',
    placeholder: 'email address',
  },
];

export const ENTITY_SEARCH_TYPE_OPTIONS: TypeDropdownOption[] = [
  {
    key: 'Exact Entity ID',
    text: 'Exact Entity ID',
    value: 'exact_external_id',
    placeholder: 'exact entity id',
  },
  ...ENTITIES_TYPE_OPTIONS,
];

export const ENTITY_SEARCH_INDEX_MAP: Record<string, number> =
  ENTITY_SEARCH_TYPE_OPTIONS.reduce(
    (acc, i, idx) => ({ ...acc, [i.key]: idx }),
    {},
  );

export const RESOURCE_DROPDOWN_ITEMS: ResourceDropdownItem[] = [
  {
    icon: IconUser,
    text: 'Entities',
    value: SearchTypes.ENTITIES,
    placeholder: 'Search for entities by',
    formatSearchResult: formatEntityTopBarSearch,
    permission: permissions.readEntities,
  },
  {
    icon: IconCoin,
    text: 'Transactions',
    value: SearchTypes.TRANSACTIONS,
    placeholder: 'Search for transactions by',
    formatSearchResult: formatEventTopBarSearch,
    permission: permissions.readEvents,
  },
  {
    icon: IconLock,
    text: 'Action Events',
    value: SearchTypes.ACTION_EVENTS,
    placeholder: 'Search for action events by',
    formatSearchResult: formatActionEventsTopBarSearch,
    resultsSelector: selectActionEventsSearchResults,
    permission: permissions.readEvents,
  },
  {
    icon: IconCreditCard,
    text: 'Instruments',
    value: SearchTypes.INSTRUMENTS,
    placeholder: 'Search for instruments by',
    formatSearchResult: formatInstrumentTopBarSearch,
    permission: permissions.readTxnInstruments,
  },
  {
    icon: IconScale,
    text: 'Rules',
    value: SearchTypes.RULES,
    placeholder: 'Search for rules by id, title or description',
    formatSearchResult: formatRuleTopBarSearch,
    permission: permissions.readRules,
  },
  {
    icon: IconFlag,
    text: 'Alerts',
    value: SearchTypes.ALERTS,
    placeholder: 'Search for alerts by id, external id, title or description',
    formatSearchResult: formatAlertTopBarSearch,
    permission: permissions.readAlerts,
  },
  {
    icon: IconBriefcase,
    text: 'Cases',
    value: SearchTypes.CASES,
    placeholder: 'Search for cases by id, title or description',
    formatSearchResult: formatCaseTopBarSearch,
    permission: permissions.readCases,
  },
  {
    icon: IconUsers,
    text: 'Teams',
    value: SearchTypes.TEAMS,
    placeholder: 'Search for teams by id, name or description',
    formatSearchResult: formatTeamTopBarSearch,
    permission: permissions.readTeams,
  },
];

export const RESOURCE_TO_TYPE_DROPDOWN_OPTIONS: Partial<
  Record<SearchTypes, TypeDropdownOption[]>
> = {
  [SearchTypes.ENTITIES]: [...ENTITIES_TYPE_OPTIONS],
  [SearchTypes.TRANSACTIONS]: [...EVENTS_TYPE_OPTIONS],
  [SearchTypes.ACTION_EVENTS]: [...ACTION_EVENTS_TYPE_OPTIONS],
  [SearchTypes.INSTRUMENTS]: [...INSTRUMENTS_TYPE_OPTIONS],
};

export const OLD_TO_NEW_SEARCH_TYPE_MAP: Record<string, EntitySearchOptions> = {
  exact_external_id: EntitySearchOptions.EXTERNAL_ID,
  external_id: EntitySearchOptions.EXTERNAL_ID,
  name: EntitySearchOptions.BUSINESS_NAME,
  user_name: EntitySearchOptions.USER_NAME,
  email_address: EntitySearchOptions.EMAIL_ADDRESSES,
} as const;

export const ALERT_SEARCH_CONDITIONS: SearchCondition<AlertSearchOptions>[] = [
  {
    field: AlertSearchOptions.UNIT21_ID,
    match_type: MatchType.EXACT,
  },
  {
    field: AlertSearchOptions.EXTERNAL_ID,
    match_type: MatchType.EXACT,
  },
  {
    field: AlertSearchOptions.TITLE,
    match_type: MatchType.PARTIAL,
  },
  {
    field: AlertSearchOptions.DESCRIPTION,
    match_type: MatchType.PARTIAL,
  },
] as const;
