import { selectSearchV2Enabled } from 'app/shared/featureFlags/selectors';
import {
  U21AlertSelect,
  U21AlertSelectProps,
} from 'app/shared/u21-ui/components/dashboard';
import {
  U21AlertSearch,
  U21AlertSearchProps,
} from 'app/shared/u21-ui/components/dashboard/U21AlertSearch';
import { useSelector } from 'react-redux';

// This component is temporary and is to be removed once we GA search v2
export const CasesAlertSearch = (
  props: U21AlertSearchProps<false> | U21AlertSelectProps<false>,
) => {
  const isSearchV2Enabled = useSelector(selectSearchV2Enabled);

  return isSearchV2Enabled ? (
    <U21AlertSearch
      {...(props as U21AlertSearchProps<false>)}
      clearable={false}
    />
  ) : (
    <U21AlertSelect
      {...(props as U21AlertSelectProps<false>)}
      clearable={false}
    />
  );
};
