import { call, put, takeLatest, select } from 'redux-saga/effects';
import { isEqual } from 'lodash';
import makeSagaRequest from 'app/shared/sagas/makeSagaRequest';

// Models
import { AlertsActionTypes } from 'app/modules/alerts/models';

// Actions
import {
  reassignAlerts as reassignAlertsAction,
  reassignAlertsSuccess,
  retrieveAlerts,
  retrieveAlertAuditTrail,
} from 'app/modules/alerts/actions';
import { sendSuccessToast } from 'app/shared/toasts/actions';

// Selectors
import { selectAlert } from 'app/modules/alerts/selectors';
import { selectDLEnabledForAlertList } from 'app/shared/featureFlags/selectors';

// API
import { reassignAlerts as reassignAlertsApi } from 'app/shared/api/alerts';

const rootAction = AlertsActionTypes.REASSIGN_ALERTS;
function* reassignAlerts({
  payload,
  meta,
}: ReturnType<typeof reassignAlertsAction>) {
  const { handleSuccess, alertFilters } = meta || {};

  const config = {
    rootAction,
    errorToastMessage: 'Something went wrong, unable to reassign alerts.',
    request: call(reassignAlertsApi, payload),
    success: function* onSuccess(result) {
      yield put(reassignAlertsSuccess(result));
      handleSuccess?.();
      if (alertFilters) {
        const isDLEnabledForAlertList = yield select(
          selectDLEnabledForAlertList,
        );
        yield put(retrieveAlerts(alertFilters, isDLEnabledForAlertList));
      }
      yield put(sendSuccessToast(`Alerts successfully reassigned.`));

      // To account for if we are currently viewing an alert
      const { id } = yield select(selectAlert);
      if (isEqual(payload.alert_ids, [id])) {
        yield put(retrieveAlertAuditTrail(id));
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchReassignAlerts() {
  yield takeLatest(rootAction, reassignAlerts);
}
