import { GetAlertsGroupedByObjectPayload } from 'app/modules/alerts/types/objectCentricInvestigation';
import {
  GetAlertsPayload,
  RetrieveAlertEntitiesPayload,
} from 'app/modules/alerts/models';
import {
  GetAlertsCachedListPayload,
  GetAlertHistogramPayload,
} from 'app/modules/alerts/requests';
import { GetTransactionsPayload } from 'app/modules/transactions/requests';
import { PaginationPayload } from 'app/shared/pagination/models';
import { InvestigationsPayload } from 'app/modules/investigations/types';
import { AlertSearchPayload } from 'app/modules/search/payload';

const BASE = ['alerts'] as const;
export const ALERT_QUERY_KEYS = {
  getAlert: (payload: number, useCacheEndpoint: boolean = false) => {
    if (useCacheEndpoint) {
      return [...BASE, 'alert', payload, useCacheEndpoint] as const;
    }
    return [...BASE, 'alert', payload] as const;
  },
  getAlerts: (payload: GetAlertsPayload, useCacheEndpoint: boolean = false) => {
    if (useCacheEndpoint) {
      return [...BASE, 'alerts', payload, useCacheEndpoint] as const;
    }
    return [...BASE, 'alerts', payload] as const;
  },
  getAlertsFull: (
    payload: Omit<InvestigationsPayload, 'object_type' | 'short'>,
    useCacheEndpoint: boolean = false,
  ) => {
    if (useCacheEndpoint) {
      return [...BASE, 'alerts-full', payload, useCacheEndpoint] as const;
    }
    return [...BASE, 'alerts-full', payload] as const;
  },
  getAlertsCount: (
    payload: GetAlertsPayload,
    useCacheEndpoint: boolean = false,
  ) => {
    if (useCacheEndpoint) {
      return [...BASE, 'count', payload, useCacheEndpoint] as const;
    }
    return [...BASE, 'count', payload] as const;
  },
  getAlertsGroupedByObject: (payload: GetAlertsGroupedByObjectPayload) =>
    [...BASE, 'grouped', payload] as const,
  getAlertDispositionByAlertIds: (alertIds: number[]) => {
    return [
      'alert-disposition',
      'getAlertDispositionByAlertIds',
      alertIds,
    ] as const;
  },
  getAlertEntities: (payload: RetrieveAlertEntitiesPayload) =>
    [...BASE, 'entities', payload] as const,
  getAlertTransactions: (
    id: number,
    payload: GetTransactionsPayload,
    useCacheEndpoint: boolean = false,
  ) => {
    if (useCacheEndpoint) {
      return [...BASE, 'transactions', id, payload, useCacheEndpoint] as const;
    }
    return [...BASE, 'transactions', id, payload] as const;
  },
  getAlertInstruments: (id: number, payload: PaginationPayload) =>
    [...BASE, 'instruments', id, payload] as const,
  getAlertActionEvents: (id: number, payload: PaginationPayload) =>
    [...BASE, 'action-events', id, payload] as const,
  getAlertAmount: (id: number) => [...BASE, 'amount', id] as const,
  getAlertGraphRuleMatches: (id: number) =>
    [...BASE, 'entity-link-counts-matches', id] as const,
  getAlertPriorActivity: (id: number) =>
    [...BASE, 'prior-activity', id] as const,
  getHistogram: (payload: GetAlertHistogramPayload) =>
    [...BASE, 'histogram', payload] as const,
  getAlertSearch: (payload?: AlertSearchPayload) =>
    payload
      ? ([...BASE, 'search', payload] as const)
      : ([...BASE, 'search'] as const),
  getAlertsCachedList: (payload: GetAlertsCachedListPayload) =>
    [...BASE, 'cached-list', payload] as const,
};
