import { FILTER_OPERATOR, FILTER_TYPE } from 'app/modules/filters/constants';
import { FilterOption } from 'app/modules/filters/models';
import {
  ContainsComparisonFilter,
  SimpleComparisonFilter,
} from 'app/modules/filtersOld/models';

/*

The fields are taken from Alert.content, a JSONB column and, while
some can be accounted for in ChainalysisIngestor (1), some only
exist by reference in the old table

1) u21/umbridge/ingest/chainalysis.py::ChainalysisIngestor

*/

// Type definition for individual chainalysis filter
export type ChainalysisFilter = Pick<
  SimpleComparisonFilter | ContainsComparisonFilter,
  'key' | 'value' | 'operator'
>;

// Type definition for the chainalysis_filters array in AlertFiltersPayload
export type ChainalysisFilters = ChainalysisFilter[];

export enum ChainalysisContentField {
  ALERT_IDENTIFIER = 'alertIdentifier',
  ASSET = 'asset',
  LEVEL = 'level',
  USER_ID = 'userId',
  SERVICE = 'service',
  CATEGORY = 'category',
  CREATED_AT = 'createdAt',
  DIRECTION = 'direction',
  TIMESTAMP = 'timestamp',
  ALERT_STATUS = 'alertStatus',
  EXPOSURE_TYPE = 'exposureType',
  MIN_THRESHOLD = 'minThreshold',
  MAX_THRESHOLD = 'maxThreshold',
  TRANSACTION_HASH = 'transactionHash',
  TRANSACTION_INDEX = 'transactionIndex',
  TRANSFER_REFERENCE = 'transferReference',
  TRANSFER_REPORTED_AT = 'transferReportedAt',
  TRANSFER_OUTPUT_ADDRESS = 'transferOutputAddress',
  KYT_ALERT_URL = 'kytAlertUrl',
  ALERT_AMOUNT_USD = 'alertAmountUsd',
  ALERT_STATUS_CREATED_AT = 'alertStatusCreatedAt',
  ALERT_STATUS_CREATED_BY = 'alertStatusCreatedBy',
  RULE = 'rule',
  TRANSFERRED_VALUE_PERCENTAGE = 'transferredValuePercentage',
}

export type ChainalysisAlertContent = {
  [key in ChainalysisContentField]?: string;
};

const CHAINALYSIS_FILTER_CUSTOMIZE_OPTIONS = {
  [FILTER_OPERATOR.IS_ONE_OF]: {
    inputProps: { creatable: true, options: [] },
  },
  [FILTER_OPERATOR.IS_NOT_ONE_OF]: {
    inputProps: { creatable: true, options: [] },
  },
};

export const CHAINALYSIS_FILTER_LABELS = {
  [ChainalysisContentField.SERVICE]: 'Chainalysis service',
  [ChainalysisContentField.LEVEL]: 'Chainalysis level',
  [ChainalysisContentField.CATEGORY]: 'Chainalysis category',
};

export const CHAINANALYSIS_FILTER_OPTIONS: FilterOption[] = [
  {
    key: ChainalysisContentField.SERVICE,
    label: CHAINALYSIS_FILTER_LABELS[ChainalysisContentField.SERVICE],
    // TODO: SC-88804 - Change this to a chainalysis filter text type
    type: FILTER_TYPE.CUSTOM_DATA_TEXT,
    customize: CHAINALYSIS_FILTER_CUSTOMIZE_OPTIONS,
  },
  {
    key: ChainalysisContentField.LEVEL,
    label: CHAINALYSIS_FILTER_LABELS[ChainalysisContentField.LEVEL],
    // TODO: SC-88805 - Change this to an ENUM filter type
    type: FILTER_TYPE.CUSTOM_DATA_TEXT,
    customize: CHAINALYSIS_FILTER_CUSTOMIZE_OPTIONS,
  },
  {
    key: ChainalysisContentField.CATEGORY,
    label: CHAINALYSIS_FILTER_LABELS[ChainalysisContentField.CATEGORY],
    // TODO: SC-88805 - Change this to an ENUM filter type
    type: FILTER_TYPE.CUSTOM_DATA_TEXT,
    customize: CHAINALYSIS_FILTER_CUSTOMIZE_OPTIONS,
  },
];

export const CHAINALYSIS_FILTER_KEYS_SET = new Set(
  CHAINANALYSIS_FILTER_OPTIONS.map((option) => option.key),
);
