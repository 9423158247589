import { call, put, takeLatest } from 'redux-saga/effects';
import makeSagaRequest, {
  MakeRequestProps,
} from 'app/shared/sagas/makeSagaRequest';

// Models
import {
  AlertsActionTypes,
  GetAlertsListResponse,
  GetAlertsPayload,
} from 'app/modules/alerts/models';
import { ShortAlertsCachedResponse } from 'app/modules/alerts/types/responses';

// Actions
import {
  retrieveAlerts as retrieveAlertsAction,
  retrieveAlertsSuccess,
  clearAlertsList,
} from 'app/modules/alerts/actions';
import { sendErrorToast } from 'app/shared/toasts/actions';

// API
import { getAlerts } from 'app/shared/api/alerts';

export const getRetrieveAlertsTimeoutErrorMessage = (
  payload: GetAlertsPayload,
): string => {
  // Queries with CDF can timeout if the customer has a lot of data
  if (
    payload.custom_data_filters &&
    Object.keys(payload.custom_data_filters).length > 0
  ) {
    return 'Custom data filters took too long to resolve. Try adding some predefined filters to narrow your search criteria.';
  }

  return 'Server took too long to respond. Please try again and contact us if the problem persists.';
};

const rootAction = AlertsActionTypes.RETRIEVE_ALERTS;
function* retrieveAlerts({
  payload: { useCacheEndpoint, ...payload },
}: ReturnType<typeof retrieveAlertsAction>) {
  const config: MakeRequestProps = {
    rootAction,
    request: call(getAlerts, payload, useCacheEndpoint),
    success: function* onSuccess(
      result: ShortAlertsCachedResponse | GetAlertsListResponse,
    ) {
      if (!payload.appendToExisting) {
        yield put(clearAlertsList());
      }
      yield put(retrieveAlertsSuccess(result));
    },
    error: function* onError(_, errorCode) {
      if (errorCode === 504) {
        yield put(
          sendErrorToast(getRetrieveAlertsTimeoutErrorMessage(payload)),
        );
        yield put(clearAlertsList());
      }
    },
  };

  yield call(makeSagaRequest, config);
}

export default function* watchRetrieveAlerts() {
  yield takeLatest(rootAction, retrieveAlerts);
}
