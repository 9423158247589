import { AlertSummary } from 'app/modules/alerts/models';
import { ShortAlertResponseFast } from 'app/modules/alerts/types/responses';
import { InvestigationTableColumn } from 'app/modules/investigations/models';

import { INVESTIGATION_COLUMN_CONFIG } from 'app/modules/investigations/columns';
import { ROUTES_MAP } from 'app/shared/utils/routes';
import {
  ALERT_TYPE_LABELS,
  ALERT_SCORE_TOOLTIP,
} from 'app/modules/alerts/constants';

import { AlertAmount } from 'app/modules/alerts/components/AlertAmount';
import {
  IconAlertTriangle,
  IconArrowRight,
  IconClipboardList,
  IconCreditCard,
  IconFlag,
  IconScale,
} from '@u21/tabler-icons';
import {
  U21HelpTooltip,
  U21TableBodyCellProps,
  U21TableColumn,
  U21Typography,
} from 'app/shared/u21-ui/components';
import { AlertScoreLabel } from 'app/modules/investigations/components/SummaryCard/AlertScoreLabel';
import { DeadlineChip } from 'app/modules/deadlines/components/DeadlineChip';
import { ArticleDeadline } from 'app/modules/deadlines/models';

export type AlertTableType = AlertSummary | ShortAlertResponseFast;
export type AlertTableColumn = U21TableColumn<AlertTableType, any>;

const AMOUNT_COLUMN: AlertTableColumn = {
  id: 'amount_volume',
  Cell: (props: U21TableBodyCellProps<AlertTableType>) => {
    const { row } = props;
    return <AlertAmount alert={row} />;
  },
  headerIcon: <IconCreditCard />,
};

export const GOTO_COLUMN: AlertTableColumn = {
  id: 'goto',
  type: 'button',
  cellProps: (alert) => ({
    children: <IconArrowRight />,
    to: ROUTES_MAP.alertsId.path.replace(':id', String(alert.id)),
  }),
  disableSortBy: true,
};

const QUEUE_COLUMN: AlertTableColumn = {
  id: 'queue.title',
  accessor: (alert) => alert['queue.title'],
  headerIcon: <IconClipboardList />,
};

const QUEUE_TEAMS_COLUMN: AlertTableColumn = {
  id: 'queue.teams',
  accessor: (alert) => (alert['queue.teams'] || []).map((i) => i.name),
  type: 'list',
};

const RULES_COLUMN: AlertTableColumn = {
  id: 'rules',
  accessor: (row) => row.rules.map((i) => i.title),
  cellProps: {
    getShowLessText: () => 'Show fewer rules',
    getShowMoreText: (length) => `Show all ${length} rules`,
  },
  headerIcon: <IconScale />,
  type: 'list',
  disableSortBy: true,
};

export const ALERT_SCORE_COLUMN: AlertTableColumn = {
  id: 'alert_score',
  Header: () => (
    <>
      <U21Typography
        icon={<IconAlertTriangle />}
        color="text.secondary"
        variant="subtitle2"
      >
        Alert Score
      </U21Typography>
      <U21HelpTooltip help={ALERT_SCORE_TOOLTIP} style={{ marginTop: 0 }} />
    </>
  ),
  Cell: (
    props: U21TableBodyCellProps<AlertTableType, AlertTableType['alert_score']>,
  ) => {
    const { value } = props;
    return <AlertScoreLabel alertScore={value} />;
  },
  width: 170,
};

export const TITLE_COLUMN: InvestigationTableColumn = {
  id: 'title',
  cellProps: { variant: 'subtitle2' },
  headerIcon: <IconFlag />,
  width: 300,
};

const TYPE_COLUMN: AlertTableColumn = {
  id: 'alert_type',
  accessor: (row) => ALERT_TYPE_LABELS[row.alert_type],
};

const DEADLINE_COLUMN: AlertTableColumn = {
  id: 'deadline',
  type: 'label',
  Cell: ({
    value,
    row,
  }: U21TableBodyCellProps<AlertTableType, ArticleDeadline>) => (
    <DeadlineChip
      articleDeadline={value}
      deadlinesConfig={row.deadline_config}
      closed={row.status !== 'OPEN'}
    />
  ),
};

export const ALERT_COLUMN_CONFIG = {
  ...INVESTIGATION_COLUMN_CONFIG,
  [ALERT_SCORE_COLUMN.id]: ALERT_SCORE_COLUMN,
  [AMOUNT_COLUMN.id]: AMOUNT_COLUMN,
  [QUEUE_COLUMN.id]: QUEUE_COLUMN,
  [QUEUE_TEAMS_COLUMN.id]: QUEUE_TEAMS_COLUMN,
  [RULES_COLUMN.id]: RULES_COLUMN,
  [TITLE_COLUMN.id]: TITLE_COLUMN,
  [TYPE_COLUMN.id]: TYPE_COLUMN,
  [DEADLINE_COLUMN.id]: DEADLINE_COLUMN,
} as Record<string, AlertTableColumn>; // cast to make typing more specific to alerts
