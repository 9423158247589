import { useMemo } from 'react';

// Components
import {
  U21Button,
  U21Section,
  U21Table,
  U21TableColumn,
  U21TableColumnTypes,
} from 'app/shared/u21-ui/components';

import {
  U21CustomDataProps,
  CustomData,
} from 'app/shared/u21-ui/components/customData/models';

// Constants
import { CUSTOM_DATA_DOCS } from 'app/shared/constants/docs';
import { DarkWebAlertCustomData } from 'app/modules/alerts/types/responses';
import { U21_DOCS_PASSWORD_TOOLTIP } from 'app/shared/u21-ui/components/dashboard';

interface CustomDataTableData {
  id: string;
  column: string;
  value: string;
  description: string;
}

const CUSTOM_DATA_COLUMNS: U21TableColumn<CustomDataTableData>[] = [
  {
    accessor: 'column',
    id: 'column',
    Header: 'Key',
    type: U21TableColumnTypes.TEXT,
  },
  {
    accessor: 'description',
    id: 'description',
    Header: 'Description',
    type: U21TableColumnTypes.TEXT,
  },
  {
    accessor: 'value',
    id: 'value',
    Header: 'Value',
    type: U21TableColumnTypes.TEXT,
  },
];

interface OwnProps extends Omit<U21CustomDataProps, 'value'> {
  // U21CustomData should provide an empty object if value is falsey
  value: CustomData | DarkWebAlertCustomData;

  toggleEditing?: () => void;
}

export const ShowCustomData = (props: OwnProps) => {
  const { value, disabled, toggleEditing, customDataKeyValuesMap } = props;

  const customDataRows = useMemo<CustomDataTableData[]>(
    () =>
      Object.keys(value).map((customDataKey) => {
        const customDataSetting = customDataKeyValuesMap?.[customDataKey];
        const customDataKeyWithLabel =
          customDataSetting?.label || customDataKey;
        const customDataSettingDescription =
          customDataSetting?.description || '';
        const providedValue = value[customDataKey];
        const formattedValue =
          typeof providedValue === 'string'
            ? providedValue
            : JSON.stringify(providedValue);

        return {
          id: customDataKey,
          column: customDataKeyWithLabel,
          value: formattedValue,
          description: customDataSettingDescription,
        };
      }),
    [value, customDataKeyValuesMap],
  );

  const editButton =
    Boolean(toggleEditing) && !disabled ? (
      <U21Button onClick={toggleEditing}>Edit</U21Button>
    ) : undefined;

  return (
    <U21Section
      title="Custom Data"
      action={editButton}
      helpModalProps={{
        children: `Custom data is a JSON object that contains values in a key:value format, check out the documentation to learn more.`,
        docsLink: CUSTOM_DATA_DOCS,
        docsLinkProps: { tooltip: U21_DOCS_PASSWORD_TOOLTIP },
      }}
    >
      <U21Table columns={CUSTOM_DATA_COLUMNS} data={customDataRows} />
    </U21Section>
  );
};
