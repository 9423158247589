import { useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

// Components
import {
  U21SelectSearch,
  U21SelectSearchProps,
} from 'app/shared/u21-ui/components';
import { IconSearch } from '@u21/tabler-icons';

// Selectors
import { selectHasReadAlertsPermission } from 'app/modules/session/selectors';

// Constants
import { ALERT_SEARCH_CONDITIONS } from 'app/modules/search/constants';
import { INFINITE_SCROLL_LIMIT, OFFSET_DEFAULT } from 'app/shared/constants';

// Helpers
import { formatAlertOptions } from 'app/modules/search/helpers';
import { useQueryClient } from '@tanstack/react-query';
import { AlertSearchPayload } from 'app/modules/search/payload';
import {
  searchAlert,
  useAlertSearch,
} from 'app/modules/alerts/queries/useAlertSearch';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';

export type U21AlertSearchProps<TClearable extends boolean> = DistributiveOmit<
  U21SelectSearchProps<number, TClearable>,
  'options' | 'onSearch'
>;

export const U21AlertSearch = <TClearable extends boolean>(
  props: U21AlertSearchProps<TClearable>,
) => {
  const { value } = props;

  const queryClient = useQueryClient();

  const [payload, setPayload] = useState<AlertSearchPayload>();

  const hasReadAlertsPermissions = useSelector(selectHasReadAlertsPermission);

  const {
    alerts: data,
    query: { isFetching: loading },
  } = useAlertSearch(payload);

  const options = useMemo(() => formatAlertOptions(data), [data]);

  // calledRef is used to prevent an infinite loop in case the API never returns with the missing value
  const calledRef = useRef(false);
  useEffect(() => {
    calledRef.current = false;
  }, [value]);

  useEffect(() => {
    if (hasReadAlertsPermissions) {
      let missingValues: number[] = [];

      if (Array.isArray(value)) {
        missingValues = value.filter(
          (i) => !options.find((j) => j.value === i),
        );
      } else if (value !== undefined) {
        const valueExists = options.find((i) => i.value === value);
        if (!valueExists) {
          missingValues = [value];
        }
      }

      if (missingValues.length && !calledRef.current) {
        calledRef.current = true;

        const initialPayload = {
          phrases: missingValues.map(String),
          offset: OFFSET_DEFAULT,
          limit: missingValues.length,
          all_phrases_must_match: false,
          search_conditions: ALERT_SEARCH_CONDITIONS,
        };

        queryClient.prefetchQuery({
          queryKey: ALERT_QUERY_KEYS.getAlertSearch(initialPayload),
          queryFn: () => searchAlert(initialPayload),
        });

        setPayload(initialPayload);
      }
    }
  }, [hasReadAlertsPermissions, options, queryClient, value]);

  const onSearch = useCallback(
    (query?: string) => {
      if (query) {
        setPayload({
          phrases: query ? [query] : [],
          offset: OFFSET_DEFAULT,
          limit: INFINITE_SCROLL_LIMIT,
          all_phrases_must_match: false,
          search_conditions: ALERT_SEARCH_CONDITIONS,
        });
      }
    },

    [],
  );

  if (!hasReadAlertsPermissions) {
    return null;
  }

  return (
    <U21SelectSearch
      clearable
      loading={loading}
      options={options}
      onSearch={onSearch}
      placeholder="Any"
      startIcon={<IconSearch />}
      {...props}
    />
  );
};
