import { useQuery, useQueryClient } from '@tanstack/react-query';
import { ALERT_QUERY_KEYS } from 'app/modules/alerts/queries/keys';
import { AlertSearchPayload } from 'app/modules/search/payload';
import {
  AlertSearchV2Response,
  AlertSource,
} from 'app/modules/search/response';
import { post } from 'app/shared/utils/fetchr';
import { unionBy } from 'lodash';
import { useMemo } from 'react';

const getAlertSources = (data: AlertSearchV2Response): AlertSource[] =>
  data.hits.map((hit) => hit.source);

export const searchAlert = (payload?: AlertSearchPayload) =>
  post<AlertSearchV2Response>('/alerts/search/v2', payload);

export const useAlertSearch = (
  payload?: AlertSearchPayload,
  enabled: boolean = true,
) => {
  const queryClient = useQueryClient();

  const query = useQuery<AlertSearchV2Response, Error, AlertSource[]>({
    queryFn: () => searchAlert(payload),
    queryKey: ALERT_QUERY_KEYS.getAlertSearch(payload),
    enabled: Boolean(enabled && !!payload),
    staleTime: 5 * 60 * 1000,
    select: (data) => getAlertSources(data),
  });

  const cachedQueryData = queryClient.getQueriesData<AlertSearchV2Response>({
    queryKey: ALERT_QUERY_KEYS.getAlertSearch(),
  });

  const alerts = useMemo(
    () =>
      cachedQueryData.reduce<AlertSource[]>((acc, i) => {
        const [, queryData] = i;
        if (queryData) {
          return unionBy(getAlertSources(queryData), acc, 'id');
        }
        return acc;
      }, []),

    [cachedQueryData],
  );

  return {
    query,
    alerts,
  };
};
