// Models
import { AlertSummary, AlertDetails } from 'app/modules/alerts/models';
import { CaseSummary } from 'app/modules/casesOld/models';

// Types
import { AlertDetailsTab } from 'app/modules/alerts/types';
import { U21PermissionedTabRouterItem } from 'app/shared/u21-ui/components/dashboard';
import { U21TableColumn, U21TabRouterItem } from 'app/shared/u21-ui/components';
import { FullAttachmentResponse } from 'app/modules/attachments/types';
import { ShortAlertResponseFast } from 'app/modules/alerts/types/responses';

// Since AlertDetails/CaseDetails extend their respective Summary
// interfaces, the Investigation type is also equivalent to them
export type Investigation = AlertSummary | ShortAlertResponseFast | CaseSummary;

export enum InvestigationType {
  ALERT = 'ALERT',
  CASE = 'CASE',
}

export enum InvestigationStatus {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  IN_REVIEW = 'IN_REVIEW',
}

export enum InvestigationSource {
  INTERNAL = 'INTERNAL',
  EXTERNAL = 'EXTERNAL',
}

export enum SummaryItem {
  ALERT_SCORE = 'ALERT_SCORE',
  ALERT_TYPE = 'ALERT_TYPE',
  ASSOCIATED_ENTITIES = 'ASSOCIATED_ENTITIES',
  ASSOCIATED_TXN_INSTRUMENTS = 'ASSOCIATED_TXN_INSTRUMENTS',
  CHAINALYSIS_SOURCE = 'CHAINALYSIS_SOURCE',
  CREATED_BY = 'CREATED_BY',
  CREATION_DATE = 'CREATION_DATE',
  DEADLINE = 'DEADLINE',
  DESCRIPTION = 'DESCRIPTION',
  DISPOSITION = 'DISPOSITION',
  DISPOSITION_ACTION = 'DISPOSITION_ACTION',
  DISPOSITION_NOTES = 'DISPOSITION_NOTES',
  DISPOSITIONED_AT = 'DISPOSITIONED_AT',
  END_DATE = 'END_DATE',
  EXTERNAL_ID = 'EXTERNAL_ID',
  FLAGGED_AT = 'FLAGGED_AT',
  KYT_ALERT_URL = 'KYT_ALERT_URL',
  RULES_TRIGGERED = 'RULES_TRIGGERED',
  START_DATE = 'START_DATE',
  STATUS = 'STATUS',
  SUBDISPOSITION = 'SUBDISPOSITION',
  TAGS = 'TAGS',
  TOTAL_FLAGGED_AMOUNT = 'TOTAL_FLAGGED_AMOUNT',
  TOTAL_FLAGGED_COUNT = 'TOTAL_FLAGGED_COUNT',
  TRANSACTION_HASH = 'TRANSACTION_HASH',
}

export interface SummaryCardLayout {
  // Summary fields that get rendered in the first column
  firstColumn: SummaryItem[];
  // Summary fields that get rendered in the second column
  secondColumn: SummaryItem[];
  // Summary fields that get rendered under the two columns, should be associated entities/instruments
  associatedTables: SummaryItem[];
}

export type CustomAndResultingTabs = [AlertDetailsTab[], U21TabRouterItem[]];

export interface PaginatedInvestigations {
  investigations: Investigation[];
  count: number;
}

export type InvestigationTableColumn = U21TableColumn<Investigation, any>;

export interface InvestigationTabs
  extends Omit<U21PermissionedTabRouterItem, 'disabled'> {
  labelString?: string;
}
export interface AlertFromAlertLocationState {
  alertFromAlert: boolean;
  alertData: Omit<
    AlertDetails,
    'custom_data' | 'alert_subtype' | 'comments' | 'num_alerts' | 'editors'
  >;
  queueId?: number;
}

export interface AttachmentsFormValues {
  attachments?: FullAttachmentResponse[];
}

export interface AiInvestigationParagraphWithSource {
  sentence: string;
  sources: number[];
  key: string;
}
