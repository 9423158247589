export enum MatchType {
  PARTIAL = 'partial',
  EXACT = 'exact',
}

enum PossibleSearchOptions {
  EXTERNAL_ID = 'external_id',
  EMAIL_ADDRESSES = 'email_addresses',
  BUSINESS_NAME = 'business_name',
  USER_NAME = 'user_name',
  TITLE = 'title',
  DESCRIPTION = 'description',
  UNIT21_ID = 'unit21_id', // Only non-CGDO have unit21_id search
}

export enum EntitySearchOptions {
  EXTERNAL_ID = PossibleSearchOptions.EXTERNAL_ID,
  EMAIL_ADDRESSES = PossibleSearchOptions.EMAIL_ADDRESSES,
  BUSINESS_NAME = PossibleSearchOptions.BUSINESS_NAME,
  USER_NAME = PossibleSearchOptions.USER_NAME,
}

export enum TransactionSearchOptions {
  EXTERNAL_ID = PossibleSearchOptions.EXTERNAL_ID,
}

export enum ActionEventSearchOptions {
  EXTERNAL_ID = PossibleSearchOptions.EXTERNAL_ID,
}

export enum InstrumentSearchOptions {
  EXTERNAL_ID = PossibleSearchOptions.EXTERNAL_ID,
}

export enum AlertSearchOptions {
  UNIT21_ID = PossibleSearchOptions.UNIT21_ID,
  EXTERNAL_ID = PossibleSearchOptions.EXTERNAL_ID,
  TITLE = PossibleSearchOptions.TITLE,
  DESCRIPTION = PossibleSearchOptions.DESCRIPTION,
}
