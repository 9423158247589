import { IconCheck, IconCopy } from '@u21/tabler-icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  U21Button,
  U21ButtonProps,
} from 'app/shared/u21-ui/components/input/U21Button';
import { useState } from 'react';

export interface U21CopyButtonProps extends U21ButtonProps {
  text: string;
}

export const U21CopyButton = (props: U21CopyButtonProps) => {
  const { children, text, tooltip = 'Copy', ...rest } = props;

  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CopyToClipboard
      text={text}
      onCopy={(_, success) => {
        setCopied(success);
        if (success) {
          setTimeout(() => {
            setCopied(false);
          }, 2000);
        }
      }}
    >
      <U21Button
        aria-label="copy"
        onClick={(e) => e.stopPropagation()}
        tooltip={copied ? 'Copied!' : tooltip}
        size="small"
        color={copied ? 'success' : undefined}
        {...(children
          ? { startIcon: copied ? <IconCheck /> : <IconCopy /> }
          : { icon: copied ? <IconCheck /> : <IconCopy /> })}
        {...rest}
      >
        {children}
      </U21Button>
    </CopyToClipboard>
  );
};
